import { LocalstorageService } from './../localstorage/localstorage.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class InterceptorService {


  constructor(
    private localstorageService: LocalstorageService,
    private router: Router
  ) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    return new Observable(observer => {
      const token = this.localstorageService.get(LocalstorageService.KEY_STORAGE.token);
      if (token && !req.url.includes('viacep.com.br') && !req.url.includes('aluguel-garantido.s3.sa-east-1.amazonaws.com') && !req.url.includes('aluguel-garantido-hom.s3.amazonaws.com')) {
        req = req.clone({
          setHeaders: {
            'x-api-key': token as any
          }
        });
      }
      const subscription = next.handle(req).subscribe(
        event => {
          if (event instanceof HttpResponse) {
            observer.next(event);
          }
        },
        err => {
          if (err.status === 403) {
            if (!req.url.includes('viacep.com.br') && !req.url.includes('aluguel-garantido-hom.s3.amazonaws.com') && !req.url.includes('aluguel-garantido.s3.sa-east-1.amazonaws.com')) {
              this.localstorageService.remove();
              this.router.navigate(['/auth']);
            }
          }
          observer.error(err);
        },
        () => {
          observer.complete();
        }
      );
      return () => {
        subscription.unsubscribe();
      };
    });
  }
}
