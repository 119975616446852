import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { LocalstorageService } from './../../shared/services/localstorage/localstorage.service';
import { User } from './../../shared/interface/users.interface';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    protected http: HttpClient,
    private localstorageService: LocalstorageService,
    private router: Router,
    private messageService: MessageService
  ) { }

  createTenant(data: { name: string, email: string, password: string, schema: string }) {
    return this.http.post<{ name: string, email: string, password: string, schema: string }>(`${environment.authenticationApiBackend}/create-tenant`, data);
  }

  getActiveUser() {
    let token = this.localstorageService.get<string>(LocalstorageService.KEY_STORAGE.token);
    return this.http.get<User>(`${environment.authenticationApiBackend}/user/active`, { headers: { 'x-api-key': token || '' } });
  }

  resetPassword(email: string) {
    return this.http.post<string>(`${environment.authenticationApiBackend}/user/reset-password`, {
      email: email
    });;
  }

  login(data: { email: string, password: string }) {
    return this.http.post(`${environment.authenticationApiBackend}/login`, data);
  }

  async loginRoutine(data: {email: string, password: string}) {
    try {
      const token = await this.login(data).toPromise() as any;
      console.log(token);
      this.localstorageService.set(LocalstorageService.KEY_STORAGE.token, token.authorization);
      this.localstorageService.set(LocalstorageService.KEY_STORAGE.tenant, token.schema);
      const user = await this.getActiveUser()?.toPromise();
      console.log(user);
      this.localstorageService.set(LocalstorageService.KEY_STORAGE.user, user);
      this.router.navigate(['/']);
    } catch (err: any) {
      if (err && err.error?.message) this.messageService.add({ severity: 'error', summary: 'Erro', detail: err.error?.message });
      console.log('ERRO - onLogin', err);
    }
  }

}
