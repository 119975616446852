import { LocalstorageService } from './../localstorage/localstorage.service';
import { AuthService } from './../../../auth/service/auth.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppInitService {

  constructor(
    private authService: AuthService,
    private localstorageService: LocalstorageService
  ) { }

  onAuthState() {
    let token = this.localstorageService.get<string>(LocalstorageService.KEY_STORAGE.token);
    if (token && this.isTokenExpired(token)) {
      this.localstorageService.remove();
      token = null;
    }
    if (token) {
      return new Promise(resolve => {
        let sub = this.authService.getActiveUser().subscribe({
          next: (v: any) => {
            //ver com o jorge o retorno se for "nulo";
            console.log(v);
            resolve(v)
          },
          error: (e: any) => {
            //Tratar erros, como offline etc.
            console.error(e)
            resolve(e)
          },
          complete: () => { sub?.unsubscribe() }
        })
      })
    } else {
      return new Promise(resolve => {
        resolve(true);
      })
    }
  }

  isTokenExpired(token: string) {
    const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
    return (Math.floor((new Date).getTime() / 1000)) >= expiry;
  }
}
